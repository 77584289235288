import {
  CHECK_CONNECTION,
  CHECK_CONNECTION_SUCCESS, DELETE_STATE, FETCH_ALL_INSTALLATIONS,
  FETCH_ALL_INSTALLATIONS_SUCCESS,
  FETCH_DP_CASH_TRANSACTION_HISTORY,
  FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_INSTALLATION_DATA,
  FETCH_INSTALLATION_DATA_SUCCESS, FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS_SUCCESS, FETCH_PAYMENT_LINKS,
  FETCH_PAYMENT_LINKS_SUCCESS,
  FETCH_PAYMENT_LINK_TYPE,
  FETCH_PAYMENT_LINK_TYPE_SUCCESS, FETCH_PAYMENT_PLANS, FETCH_PAYMENT_PLANS_BY_ID,
  FETCH_PAYMENT_PLANS_BY_ID_SUCCESS, FETCH_PAYMENT_PLANS_SUCCESS, FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS,
  FETCH_PLANS_TO_BE_APPLIED_LIST,
  FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_SUCCESS,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_FAILURE,
  FETCH_PURIFIER_DETAILS,
  FETCH_PURIFIER_DETAILS_SUCCESS, FETCH_SYNC_HISTORY,
  FETCH_SYNC_HISTORY_SUCCESS, FETCH_SYNC_HISTORY_SUMMARY,
  FETCH_SYNC_HISTORY_SUMMARY_SUCCESS, FETCH_TICKETS_FOR_PURIFIER,
  FETCH_TICKETS_FOR_PURIFIER_SUCCESS,
  FETCH_TICKET_DETAILS,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TRANSACTION_HISTORY,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_WALLET_BALANCE,
  FETCH_WALLET_BALANCE_SUCCESS, GENERATE_INVOICE, GENERATE_INVOICE_SUCCESS, GENERATE_PAYMENT_LINK,
  GENERATE_PAYMENT_LINK_SUCCESS, SHOW_API_MESSAGE, CALCULATE_REFUND_AMOUNT_REQUEST, CALCULATE_REFUND_AMOUNT_SUCCESS, CALCULATE_REFUND_AMOUNT_FAILURE
} from "constants/ActionTypes";

const INIT_STATE = {
  walletData: {},
  planDetails: { loader: false },
  refund: { loader: false }

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_WALLET_BALANCE: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_WALLET_BALANCE_SUCCESS: {
      return {
        ...state,
        walletData: action.payload.walletData,
        loader: false,
      };
    }
    case FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS: {
      return {
        ...state,
        plans: action.payload.plans,
        loader: false,
      };
    }
    case FETCH_TRANSACTION_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_DP_CASH_TRANSACTION_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
        loader: false,
      };
    }
    case FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_INSTALLATIONS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_INSTALLATIONS_SUCCESS: {
      return {
        ...state,
        installation: action.payload.installation,
        totalCount: action.payload.installation.total,
        loader: false,
      };
    }
    case FETCH_PLANS_TO_BE_APPLIED_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS: {
      return {
        ...state,
        installation: action.payload.installation,
        totalCount: action.payload.installation.total,
        loader: false,
      };
    }
    case FETCH_INSTALLATION_DATA: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_INSTALLATION_DATA_SUCCESS: {
      return {
        ...state,
        installation: action.payload.installation,
        loader: false,
      };
    }
    case FETCH_SYNC_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_SYNC_HISTORY_SUCCESS: {
      if (action.payload.syncHistory.pings) {
        return {
          ...state,
          pings: action.payload.syncHistory.fetchSyncHistory.body,
          loader: false,
        };
      }
      return {
        ...state,
        syncHistory: action.payload.syncHistory.fetchSyncHistory.body,
        loader: false,
      };
    }
    case FETCH_SYNC_HISTORY_SUMMARY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_SYNC_HISTORY_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload.syncHistorySummary,
      };
    }
    case FETCH_PAYMENT_PLANS_BY_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PAYMENT_PLANS_BY_ID_SUCCESS: {
      return {
        ...state,
        plans: action.payload.paymentPlans,
        loader: false,
      };
    }
    case FETCH_PAYMENT_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        paymentDetails: action.payload.paymentDetails,
        loader: false,
      };
    }
    case FETCH_PURIFIER_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PURIFIER_DETAILS_SUCCESS: {
      return {
        ...state,
        purifier: action.payload.purifier,
        loader: false,
      };
    }
    case GENERATE_PAYMENT_LINK: {
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_INVOICE: {
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_INVOICE_SUCCESS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GENERATE_PAYMENT_LINK_SUCCESS: {
      return {
        ...state,
        link: action.payload.link,
        loader: false,
      };
    }
    case FETCH_PAYMENT_LINKS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PAYMENT_LINKS_SUCCESS: {
      return {
        ...state,
        paymentLinks: action.payload,
        loader: false,
      };
    }
    case FETCH_PAYMENT_PLANS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PAYMENT_PLANS_SUCCESS: {
      return {
        ...state,
        paymentPlans: action.payload,
        loader: false,
      };
    }
    case FETCH_TICKETS_FOR_PURIFIER: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_PAYMENT_LINK_TYPE: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_TICKETS_FOR_PURIFIER_SUCCESS: {
      return {
        ...state,
        tickets: action.payload.data,
        loader: false,
      };
    }
    case FETCH_TICKET_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        ticket: action.payload.data,
      };
    }

    case FETCH_PAYMENT_LINK_TYPE_SUCCESS: {
      return {
        ...state,
        paymentTypes: action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case CHECK_CONNECTION: {
      return {
        ...state,
        loader: true,
      };
    }
    case CHECK_CONNECTION_SUCCESS: {
      return {
        ...state,
        connData: action.payload.connData,
        loader: false,
      };
    }
    case DELETE_STATE: {
      return {
        ...state,
        summary: undefined,
        syncHistory: undefined,
        pings: undefined,
        link: undefined,
        loader: false,
      };
    }
    case FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST: {
      return {
        ...state,
        planDetails: {
          loader: true,
          ...action.payload
        }
      }
    }
    case FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_SUCCESS: {
      return {
        ...state,
        planDetails: {
          loader: false,
          ...action.payload
        }
      }
    }
    case FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_FAILURE: {
      return {
        ...state,
        planDetails: {
          loader: false,
        }
      }
    }
    case CALCULATE_REFUND_AMOUNT_REQUEST: {
      return {
        ...state,
        refund: {
          loader: true
        }
      }
    }
    case CALCULATE_REFUND_AMOUNT_SUCCESS: {
      return {
        ...state,
        refund: {
          loader: false,
          ...action.payload
        }
      }
    }
    case CALCULATE_REFUND_AMOUNT_FAILURE: {
      return {
        ...state,
        refund: {
          loader: false,
          ...action.payload
        }
      }
    }

    default:
      return state;
  }
};
