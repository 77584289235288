import {
  getPlanDetailsByPlanNameAndValidityFailure,
  getPlanDetailsByPlanNameAndValiditySuccess,
} from "actions/PaymentActions";
import {
  addSpareTransferJobResponse,
  fetchAllDeliveryPersonError,
  fetchAllDeliveryPersonSuccess,
  fetchAreaZonesByCitySuccess,
  fetchAreasSuccess,
  fetchAvailableSlotsWithParamsSuccess,
  fetchAvailableTechniciansError,
  fetchAvailableTechniciansSuccess,
  fetchCategoryAvailableSlotsWithParamsSuccess,
  fetchDependenciesSuccess,
  fetchJob,
  fetchJobError,
  fetchJobImagesSuccess,
  fetchJobSlotsSuccess,
  fetchJobStatusSuccess,
  fetchJobSuccess,
  fetchJobTagsSuccess,
  fetchJobTypesSuccess,
  fetchJobsSuccess,
  fetchLast3JobsByInstallationError,
  fetchLast3JobsByInstallationSuccess,
  fetchRepeatedJobsError,
  fetchRepeatedJobsSuccess,
  fetchTeamsError,
  fetchTeamsSuccess,
  fetchTechniciansError,
  fetchTechniciansJobSlotsError,
  fetchTechniciansJobSlotsSuccess,
  fetchTechniciansSuccess,
  fetchZonesSuccess,
  getJobsByInstallationIdError,
  getJobsByInstallationIdSuccess,
  showAPIMessage,
  updateElementAddTextError,
  updateElementAddTextSuccess,
  updateGSThresholdLimitSuccess,
  updateJobAppointmentError,
  updateJobAppointmentSuccess,
  updateJobStatusError,
  updateJobStatusSuccess,
  updateZipError,
  updateZipSuccess,
} from "actions/WorkforceActions";
import {
  updatePlanAtDeliveryFailure,
  updatePlanAtDeliverySucess,
} from "actions/deliveryJobActions";
import {
  ADD_DEVICE_REPLACEMENT_JOB,
  ADD_JOB_TO_SYSTEM,
  ADD_PAYMENT_TO_BE_COLLECTED,
  ADD_PAYMENT_WORKFLOW,
  ADD_RE_DELIVERY_JOB_TO_SYSTEM,
  CHANGE_PLAN_DURING_DELIVERY_REQUEST,
  CREATE_INITIAL_PAYMENTS,
  CREATE_SPARE_TRANSFER_JOB_REQUEST,
  FETCH_ALL_DELIVERY_PERSON,
  FETCH_ALL_JOB,
  FETCH_ALL_REPEATED_JOBS,
  FETCH_ALL_TEAMS,
  FETCH_AREAS,
  FETCH_AREA_ZONES_BY_CITY,
  FETCH_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_TECHNICIANS,
  FETCH_CATEGORY_AVAILABLE_SLOT,
  FETCH_DEPENDENCIES,
  FETCH_JOB,
  FETCH_JOBS_BY_INSTALLATION_ID,
  FETCH_JOB_IMAGES,
  FETCH_JOB_SLOTS,
  FETCH_JOB_STATUS,
  FETCH_JOB_TAGS,
  FETCH_JOB_TYPES,
  FETCH_LAST_3_JOBS_BY_INSTALLATION,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST,
  FETCH_TECHNICIANS,
  FETCH_TECHNICIANS_JOB_SLOTS,
  FETCH_ZONES,
  GENERATE_DELIVERY_CHALLAN,
  SEND_HAPPY_CODE,
  SUBMIT_RCA_DETAILS,
  UPDATE_ELEMENT_ADD_TEXT,
  UPDATE_GS_THRESHOLD_LIMITS,
  UPDATE_JOB,
  UPDATE_JOB_APPOINTMENT,
  UPDATE_JOB_STATUS,
  UPDATE_ZIP
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios, paymentAxios } from "util/Api";
import {
  toggleStatusModal,
  toggleWorkflowDataModal,
  toggleZoneModal,
} from "../actions/uiActions";

const getJobs = async function (params) {
  let jobs = [];
  try {
    const response = await axios.get("/workforce/job/dashboard/list", {
      params,
    });
    jobs = response.data;
  } catch (error) {
    console.log(error);
  }
  return jobs;
};

function* fetchJobsRequest(action) {
  try {
    const jobs = yield call(getJobs, action.payload);
    yield put(fetchJobsSuccess(jobs));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchJobSlotsRequest(action) {
  try {
    const slots = yield call(getSlots, action.payload);
    yield put(fetchJobSlotsSuccess(slots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getSlots = async function (params) {
  let slots = [];
  try {
    const response = await axios.get("/workforce/job/slots", { params });
    slots = response.data;
  } catch (error) {
    console.log(error);
  }
  return slots;
};
const getAvailableSlots = async function (params, isNew) {
  let slots = [];
  try {
    const response = await axios.get("/bookslot/available", { params });
    slots = response.data.body;
    if (!response?.data?.success) {
      NotificationManager.error(response?.data?.message ?? "Cannot find available slots with given filters")
    }
  } catch (error) {
    console.log(error);
  }
  return slots;
};

const getCategoryAvailableSlots = async function (params, isNew) {
  let slots = [];
  try {
    const response = await axios.get("/slot/availableSlots", { params });
    slots = response.data.body;
    if (!response?.data?.success) {
      NotificationManager.error(response?.data?.message ?? "Cannot find available slots with given filters")
    }
  } catch (error) {
    console.log(error);
  }
  return slots;
};

function* fetchAvailableSlotsRequest(action) {
  try {
    const slots = yield call(getAvailableSlots, action.payload);
    yield put(fetchAvailableSlotsWithParamsSuccess(slots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchCategoryAvailableSlotsRequest(action) {
  try {
    const slots = yield call(getCategoryAvailableSlots, action.payload);
    yield put(fetchCategoryAvailableSlotsWithParamsSuccess(slots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchAreasRequest(action) {
  try {
    const slots = yield call(getAreas, action.payload);
    yield put(fetchAreasSuccess(slots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getAreas = async function (params) {
  let areas = [];
  try {
    const response = await axios.get("/workforce/areas", { params });
    areas = response.data;
  } catch (error) {
    console.log(error);
  }
  return areas;
};

function* fetchJobTypesRequest(action) {
  try {
    const slots = yield call(fetchJobTypes, action.payload);
    yield put(fetchJobTypesSuccess(slots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const fetchJobTypes = async function (params) {
  let jobTypes = [];
  try {
    const response = await axios.get("/workforce/job/types", { params });
    jobTypes = response.data;
  } catch (error) {
    console.log(error);
  }
  return jobTypes;
};

function* fetchJobRequest(action) {
  try {
    const job = yield call(getJob, {
      jobId: action.payload.id,
    });
    yield put(fetchJobSuccess(job));
  } catch (error) {
    yield put(fetchJobError(error.message));
  }
}

const getJob = async function (params) {
  let job;
  try {
    const response = await axios.get("/workforce/job/" + params["jobId"], {});
    job = response.data;
  } catch (error) {
    console.log(error);
  }
  return job;
};

function* fetchJobImagesRequest(action) {
  try {
    const job = yield call(getJobImages, {
      jobId: action.payload,
    });
    yield put(fetchJobImagesSuccess(job));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getJobImages = async function (params) {
  let jobImage;
  try {
    const response = await axios.get(
      "/workforce/job/" + params["jobId"] + "/images",
      {}
    );
    jobImage = response.data;
  } catch (error) {
    console.log(error);
  }
  return jobImage;
};

function* fetchTechniciansRequest(action) {
  try {
    const technicians = yield call(fetchTechnicians, action.payload);
    yield put(fetchTechniciansSuccess(technicians));
  } catch (error) {
    yield put(fetchTechniciansError(error.message));
    NotificationManager.error(
      "Failed to fetch technicians, please try again",
      5000
    );
  }
}

const fetchTechnicians = async params => {
  try {
    const response = await axios.get("/workforce/getTechnicians", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchTechniciansJobSlotsRequest(action) {
  try {
    const technicians = yield call(fetchTechniciansJobSlots, action.payload);
    yield put(fetchTechniciansJobSlotsSuccess(technicians));
  } catch (error) {
    yield put(fetchTechniciansJobSlotsError(error.message));
    NotificationManager.error(
      "Failed to fetch technicians job slots, please try again"
    );
  }
}

const fetchTechniciansJobSlots = async params => {
  if (
    params.technician != undefined &&
    params.technician != [] &&
    params.technician != ""
  )
    params.technician = "" + params.technician;
  else {
    delete params["technician"];
  }
  if (
    params.cityFilter != undefined &&
    params.cityFilter != [] &&
    params.cityFilter != ""
  )
    params.cityFilter = "" + params.cityFilter;
  else {
    delete params["cityFilter"];
  }
  try {
    const response = await axios.get("/workforce/getTechniciansJobSlots", {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function* fetchAvailableTechniciansRequest(action) {
  try {
    const technicians = yield call(fetchAvailableTechnicians, action.payload);
    yield put(fetchAvailableTechniciansSuccess(technicians));
  } catch (error) {
    yield put(fetchAvailableTechniciansError(error.message));
    NotificationManager.error("Failed to fetch technicians, please try again");
  }
}

const fetchAvailableTechnicians = async params => {
  try {
    const response = await axios.get("/workforce/getAvailableTechnicians/v2", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchAllDeliveryPersonRequest(action) {
  try {
    const technicians = yield call(fetchAllDeliveryPerson, action.payload);
    yield put(fetchAllDeliveryPersonSuccess(technicians));
  } catch (error) {
    yield put(fetchAllDeliveryPersonError(error.message));
    NotificationManager.error(
      "Failed to fetch delivery Person , please try again"
    );
  }
}

const fetchAllDeliveryPerson = async params => {
  try {
    const response = await axios.get("/workforce/getDeliveryPerson");
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchLast3JobsByInstallationRequest(action) {
  try {
    const jobs = yield call(fetchLast3JobsByInstallation, action.payload);
    yield put(fetchLast3JobsByInstallationSuccess(jobs));
  } catch (error) {
    yield put(fetchLast3JobsByInstallationError(error.message));
    NotificationManager.error("Failed to fetch last 3 jobs, please try again");
  }
}

const fetchLast3JobsByInstallation = async params => {
  try {
    const response = await axios.get(
      "/workforce/getLast3JobsByInstallation/" + params["jobId"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
function* updateJobStatusRequest(action) {
  try {
    const response = yield call(updateJobStatus, action.payload);
    yield all([
      put(updateJobStatusSuccess(response, "Update Success")),
      put(toggleStatusModal(false)),
      put(fetchJob(action.payload.jobId)),
    ]);
    if (response.success) {
      NotificationManager.success("Job Status updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateJobStatusError(error.message))]);
    NotificationManager.error("Failed to update job status");
  }
}

const updateJobStatus = async function (params) {
  try {
    const response = await axios.put(
      "/workforce/job/" + params["jobId"] + "/status",
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateZipRequest(action) {
  try {
    const response = yield call(updateZip, action.payload);
    yield all([
      put(updateZipSuccess(response, "Update Success")),
      put(toggleZoneModal(false)),
      put(fetchJob(action.payload.jobId)),
    ]);
    if (response.success) {
      NotificationManager.success("Details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateZipError(error.message))]);
    NotificationManager.error("Failed to update zip");
  }
}

const updateZip = async function (params) {
  try {
    const response = await axios.put(
      "/customer/address/" + params["jobId"],
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
function* updateElementAddTextRequest(action) {
  try {
    const response = yield call(updateElementAddText, action.payload.params);
    yield all([
      put(updateElementAddTextSuccess(response, "Update Success")),
      put(toggleWorkflowDataModal(false)),
      put(fetchJob(action.payload.params.jobId)),
    ]);
    if (response.success) {
      NotificationManager.success("Details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateElementAddTextError(error.message))]);
    NotificationManager.error("Failed to update the value");
  }
}

const updateElementAddText = async function (params) {
  try {
    const response = await axios.post("/workflow/addText", params);
    return response.data;
  } catch (error) {
    throw error;
  }
};
function* updateJobRequest(action) {
  try {
    const response = yield call(updateJob, action.payload);
    yield all([put(fetchJob(action.payload.jobId))]);
    if (response.success) {
      NotificationManager.success("Job Updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update job details");
  }
}

const updateJob = async function (params) {
  try {
    const response = await axios.put(
      "/workforce/job/" + params["jobId"],
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateJobAppointmentRequest(action) {
  try {
    const response = yield call(updateJobAppointment, action.payload);
    yield all([
      put(updateJobAppointmentSuccess(response, "Update Success")),
      put(toggleStatusModal(false)),
      put(fetchJob(action.payload.jobId)),
    ]);
    if (response.success) {
      NotificationManager.success("Job appointment updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateJobAppointmentError(error.message))]);
    NotificationManager.error("Failed to update job appointment");
  }
}

const updateJobAppointment = async function (params) {
  try {
    const response = await axios.put(
      "/workforce/job/" + params["jobId"],
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendHappyCode = async function (params) {
  try {
    const response = await axios.get("/workforce/sendHappyCode", {
      jobId: params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addJobToSystem = async function (params) {
  try {
    const response = await axios.post(
      "/workforce/job/addJobFromSystem",
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addReDeliveryJobToSystem = async function (params) {
  try {
    const response = await axios.post(
      "/workforce/reDeliveryJob/" + params["jobId"],
      null
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createInitialPayment = async function (params) {
  try {
    const response = await axios.post(
      `/customer/installation/${params?.id}/activate`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addPaymentToBeCollected = async function (params) {
  try {
    const response = await axios.post(
      "/workforce/job/addPaymentToBeCollected",
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addDeviceReplacementJob = async function (params) {
  try {
    const response = await axios.get(
      "/workforce/createDeviceReplacementJob/" + params["installationId"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const generateDeliveryChallan = async function (params) {
  try {
    const response = await axios.post("/workforce/generateDeliveryChallan/", {
      jobList: params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
const addPaymentWorkflow = async function (params) {
  try {
    const response = await axios.get(
      "/workflow/triggerPostApiDataPayment?jobId=" + params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getZones = async function (params) {
  let zones = [];
  try {
    const response = await axios.get("/area/zones", { params });
    zones = response.data;
  } catch (error) {
    console.log(error);
  }
  return zones;
};

function* fetchZonesRequest(action) {
  try {
    const zones = yield call(getZones, action.payload);
    yield put(fetchZonesSuccess(zones));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getTags = async function (params) {
  let tags = [];
  try {
    const response = await axios.get("/workforce/job/tags", { params });
    tags = response.data;
  } catch (error) {
    console.log(error);
  }
  return tags;
};

function* fetchTagsRequest(action) {
  try {
    const tags = yield call(getTags, action.payload);
    yield put(fetchJobTagsSuccess(tags));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getStatus = async function (params) {
  let status = [];
  try {
    const response = await axios.get("/workforce/job/filteredStatuses", {
      params,
    });
    status = response.data;
  } catch (error) {
    console.log(error);
  }
  return status;
};

function* fetchStatusRequest(action) {
  try {
    const status = yield call(getStatus, action.payload);
    yield put(fetchJobStatusSuccess(status));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}
const getJobsByInstallationId = async function (params) {
  let jobs = [];
  try {
    const response = await axios.get(
      "/workforce/getJobsByInstallationId/" + params["installationId"],
      {
        params,
      }
    );
    jobs = response.data;
  } catch (error) {
    console.log(error);
  }

  return jobs;
};

function* fetchJobsByInstallationIdRequest(action) {
  try {
    const jobs = yield call(getJobsByInstallationId, action.payload);
    yield put(getJobsByInstallationIdSuccess(jobs));
  } catch (error) {
    yield put(getJobsByInstallationIdError(error));
  }
}
function* sendHappyCodeRequest(action) {
  try {
    const response = yield call(sendHappyCode, action.payload);

    if (response.success) {
      NotificationManager.success("Send Happy Code Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to activate device details");
  }
}

function* addJobToSystemRequest(action) {
  try {
    const response = yield call(addJobToSystem, action.payload);

    if (response.success) {
      NotificationManager.success("Job Added Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add Job");
  }
}

function* addReDeliveryJobToSystemRequest(action) {
  try {
    const response = yield call(addReDeliveryJobToSystem, action.payload);

    if (response.success) {
      NotificationManager.success("Job Added Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add Job");
  }
}
function* createInitialPaymentsRequest(action) {
  try {
    const response = yield call(createInitialPayment, action.payload);

    if (response.success) {
      NotificationManager.success("Created Initial Payments Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to create initial payment");
  }
}

function* addPaymentToBeCollectedRequest(action) {
  try {
    const response = yield call(addPaymentToBeCollected, action.payload);

    if (response.success) {
      NotificationManager.success("Payment Added Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add payment");
  }
}

function* addDeviceReplacementJobRequest(action) {
  try {
    const response = yield call(addDeviceReplacementJob, action.payload);

    if (response.success) {
      NotificationManager.success("Job Added Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add Job");
  }
}
function* addPaymentWorkflowRequest(action) {
  try {
    const response = yield call(addPaymentWorkflow, action.payload);

    if (response.success) {
      NotificationManager.success("Payment Added Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add payment");
  }
}
function* generateDeliveryChallanRequest(action) {
  try {
    const response = yield call(generateDeliveryChallan, action.payload);

    if (response.success) {
      NotificationManager.success("Delivery Challan Request Successfull");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to send request for delivery challan");
  }
}
const getAllTeams = async function (params) {
  let teams = [];
  try {
    const response = await axios.get("/employeeTeam/getAllTeams");
    teams = response.data;
  } catch (error) {
    console.log(error);
  }

  return teams;
};

function* fetchAllTeamsRequest(action) {
  try {
    const teams = yield call(getAllTeams, action.payload);
    yield put(fetchTeamsSuccess(teams));
  } catch (error) {
    yield put(fetchTeamsError(error));
  }
}

const updatePlanAtdeliveryapi = async params => {
  let result = {};
  try {
    const response = await axios.put(
      "workforce/changePlanDetailsDuringDelivery",
      params
    );
    result = response.data;
  } catch (error) {
    throw error;
  }
  return result;
};
function* updatePlanAtdelivery(action) {
  try {
    const res = yield call(updatePlanAtdeliveryapi, action.payload);
    if (res.success) {
      NotificationManager.success(res.message || "Success");
      yield put(updatePlanAtDeliverySucess());
    } else {
      NotificationManager.error(res.message || " Plan is not updated");
      yield put(updatePlanAtDeliveryFailure(action.payload));
    }
  } catch (error) {
    yield put(updatePlanAtDeliveryFailure(action.payload));
    NotificationManager.error(error.message || " Plan is not updated");
  }
}

const getAreaZonesByCity = async function (params) {
  let zones = [];
  try {
    const response = await axios.post("/area/getAreaZonesByCities", {
      cityCodes: params,
    });
    zones = response.data;
  } catch (error) {
    console.log(error);
  }
  return zones;
};

function* fetchAreaZonesByCityRequest(action) {
  try {
    const zones = yield call(getAreaZonesByCity, action.payload);
    yield put(fetchAreaZonesByCitySuccess(zones));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const updateGSThresholdLimit = async function (params) {
  let result = {};
  try {
    const response = await axios.put(
      "/area/updateZoneGsThresholdLimits",
      params
    );
    result = response.data;
  } catch (error) {
    throw error;
  }
  return result;
};

function* updateGSThresholdLimitRequest(action) {
  try {
    const response = yield call(updateGSThresholdLimit, action.payload);
    if (response.success) {
      NotificationManager.success("GST Threshold Limit Updated Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
    yield put(updateGSThresholdLimitSuccess(response));
  } catch (error) {
    NotificationManager.error("Failed to update GST Threshold Limit");
  }
}

const getPlanDetailsByPlanNameAndValidityApi = async function (params) {
  let resp = {};
  try {
    const response = await paymentAxios.get("/plan/getPlanDetails/", {
      params,
    });
    resp = response.data;
    if (!resp?.success) {
      NotificationManager.error(`${params?.planname} with  validity ${params?.validity} days is not present`)
    }
  } catch (error) {
    console.log(error);
  }
  return resp;
};

function* getPlanDetailsByPlanNameAndValidity(action) {
  const payload = action.payload.planDetails.params;
  try {
    const res = yield call(getPlanDetailsByPlanNameAndValidityApi, payload);
    if (res.success) {
      yield put(getPlanDetailsByPlanNameAndValiditySuccess(res.body));
    } else {
      yield put(getPlanDetailsByPlanNameAndValidityFailure(res));
    }
  } catch (error) {
    yield put(getPlanDetailsByPlanNameAndValidityFailure(error));
    console.log(error);
  }
}

const fetchAllRepeatedJobs = async function (params) {
  let jobs = [];
  try {
    const response = await axios.get("/workforce/job/repeatedJobsDashboard", {
      params,
    });

    jobs = response.data;
  } catch (error) {
    console.log(error);
  }
  return jobs;
}

function* fetchAllRepeatedJobsRequest(action) {
  try {
    const jobs = yield call(fetchAllRepeatedJobs, action.payload);
    yield put(fetchRepeatedJobsSuccess(jobs));
  } catch (error) {
    fetchRepeatedJobsError(error);
    yield put(showAPIMessage(error));
  }
}

const submitRcaDetails = async function (params) {
  let result = {};
  try {
    const response = await axios.post("/workforce/submitRepeatedJobRcaDetails", params);
    result = response.data;
  } catch (error) {
    throw error;
  }
  return result;
};

function* submitRcaDetailsRequest(action) {
  try {
    const response = yield call(submitRcaDetails, action.payload);
    if (response.success) {
      NotificationManager.success("RCA Details Submitted Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  }
  catch (error) {
    NotificationManager.error("Failed to submit RCA Details");
  }
}

const fetchDependencies = async function (params) {
  let dependencies = [];
  try {
    const response = await axios.get("/workforce/getRepeatJobDependencies", {
      params,
    });
    dependencies = response.data;
  } catch (error) {
    console.log(error);
  }
  return dependencies;
};

function* fetchDependenciesRequest(action) {
  try {
    const dependencies = yield call(fetchDependencies, action.payload);
    yield put(fetchDependenciesSuccess(dependencies));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}
export const fetchServiceDueDate = async (purifierId) => {
  let data = ""
  try {
    const response = await axios.get("/device/serviceDueDate/" + purifierId)
    data = response?.data?.body;
  }
  catch (error) {
    console.log(error)
  }
  return data;
}

const createSpareTransferJob = async (payload) => {
  const { installationId, jobTag } = payload;
  try {
    let response = await axios.post("/workforce/spareTransferJob", payload);
    response = response.data;
    return response;
  } catch (error) {
    throw error;
  }
};

function* createSpareTransferJobRequest(action) {
  try {
    const response = yield call(createSpareTransferJob, action.payload);
    yield put(addSpareTransferJobResponse(response.success));

    if (response.success) {
      NotificationManager.success("Success: " + response.message, "", 10000);
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    NotificationManager.error("Failed: " + error, "", 10000);
    yield put(showAPIMessage(error));
  }
}
  
export function* fetchJobsData() {
  yield takeLatest(FETCH_ALL_JOB, fetchJobsRequest);
  yield takeLatest(FETCH_JOB, fetchJobRequest);
  yield takeLatest(UPDATE_JOB, updateJobRequest);
  yield takeLatest(UPDATE_JOB_STATUS, updateJobStatusRequest);
  yield takeLatest(UPDATE_ZIP, updateZipRequest);
  yield takeLatest(FETCH_JOB_SLOTS, fetchJobSlotsRequest);
  yield takeLatest(FETCH_AREAS, fetchAreasRequest);
  yield takeLatest(FETCH_ZONES, fetchZonesRequest);
  yield takeLatest(FETCH_JOB_TYPES, fetchJobTypesRequest);
  yield takeLatest(FETCH_JOB_IMAGES, fetchJobImagesRequest);
  yield takeLatest(FETCH_TECHNICIANS, fetchTechniciansRequest);
  yield takeLatest(
    FETCH_TECHNICIANS_JOB_SLOTS,
    fetchTechniciansJobSlotsRequest
  );
  yield takeLatest(
    FETCH_AVAILABLE_TECHNICIANS,
    fetchAvailableTechniciansRequest
  );
  yield takeLatest(FETCH_ALL_DELIVERY_PERSON, fetchAllDeliveryPersonRequest);
  yield takeLatest(
    FETCH_LAST_3_JOBS_BY_INSTALLATION,
    fetchLast3JobsByInstallationRequest
  );

  yield takeLatest(UPDATE_JOB_APPOINTMENT, updateJobAppointmentRequest);
  yield takeLatest(SEND_HAPPY_CODE, sendHappyCodeRequest);
  yield takeLatest(ADD_JOB_TO_SYSTEM, addJobToSystemRequest);
  yield takeLatest(
    ADD_RE_DELIVERY_JOB_TO_SYSTEM,
    addReDeliveryJobToSystemRequest
  );
  yield takeLatest(CREATE_INITIAL_PAYMENTS, createInitialPaymentsRequest);
  yield takeLatest(ADD_PAYMENT_TO_BE_COLLECTED, addPaymentToBeCollectedRequest);
  yield takeLatest(ADD_DEVICE_REPLACEMENT_JOB, addDeviceReplacementJobRequest);
  yield takeLatest(GENERATE_DELIVERY_CHALLAN, generateDeliveryChallanRequest);
  yield takeLatest(FETCH_JOB_STATUS, fetchStatusRequest);
  yield takeLatest(FETCH_JOB_TAGS, fetchTagsRequest);
  yield takeLatest(FETCH_AVAILABLE_SLOTS, fetchAvailableSlotsRequest);
  yield takeLatest(FETCH_CATEGORY_AVAILABLE_SLOT, fetchCategoryAvailableSlotsRequest);
  yield takeLatest(UPDATE_ELEMENT_ADD_TEXT, updateElementAddTextRequest);
  yield takeLatest(
    FETCH_JOBS_BY_INSTALLATION_ID,
    fetchJobsByInstallationIdRequest
  );
  yield takeLatest(ADD_PAYMENT_WORKFLOW, addPaymentWorkflowRequest);
  yield takeLatest(FETCH_ALL_TEAMS, fetchAllTeamsRequest);
  yield takeLatest(CHANGE_PLAN_DURING_DELIVERY_REQUEST, updatePlanAtdelivery);
  yield takeLatest(FETCH_AREA_ZONES_BY_CITY, fetchAreaZonesByCityRequest);
  yield takeLatest(UPDATE_GS_THRESHOLD_LIMITS, updateGSThresholdLimitRequest);
  yield takeLatest(
    FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST,
    getPlanDetailsByPlanNameAndValidity
  );
  yield takeLatest(FETCH_ALL_REPEATED_JOBS, fetchAllRepeatedJobsRequest);
  yield takeLatest(SUBMIT_RCA_DETAILS, submitRcaDetailsRequest);
  yield takeLatest(FETCH_DEPENDENCIES, fetchDependenciesRequest);
  yield takeLatest(CREATE_SPARE_TRANSFER_JOB_REQUEST, createSpareTransferJobRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchJobsData)]);
}
