import {
  fetchAllCategoriesSuccess,
  fetchDaysSuccess, fetchIssueTypesSuccess,
  fetchSlotCategoryCountByZonesSuccess,
  fetchSlotCountByZoneAndIssue,
  fetchSlotCountByZoneAndIssueSuccess,
  fetchSlotZone,
  fetchSlotZoneError,
  fetchSlotZoneSuccess,
  fetchSlotZonesSuccess,
  fetchSlotsSuccess,
  showAPIMessage
} from "actions/SlotZoneActions";
import {
  ADD_SLOT_ZONE, FETCH_ALL_SLOT_CATEGORIES, FETCH_ALL_SLOT_ZONE,
  FETCH_DAYS, FETCH_ISSUE_TYPES,
  FETCH_SLOTS,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
  FETCH_SLOT_ZONE,
  UPDATE_SLOT_ZONE
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";


function* addSlotZoneRequest(action) {
  try {
    const response = yield call(addSlotZone, action.payload);
    yield all([put(fetchSlotCountByZoneAndIssue())]);
    if (response.success) {
      NotificationManager.success("SlotZone Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add slotzone");
  }
}

const addSlotZone = async function (params) {
  try {
    const response = await axios.post("/slotzone/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateSlotZoneRequest(action) {
  try {
    const response = yield call(updateSlotZone, action.payload);
    yield all([put(fetchSlotZone(action.payload.id))]);

    if (response.success) {
      NotificationManager.success("SlotZone details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }

  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update slotzone details");
  }
}

const updateSlotZone = async function (params) {
  try {
    const response = await axios.put("/slotzone/update", {
      ...params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSlotZones = async function (params) {
  let slotzones = [];
  try {
    const response = await axios.get("/slotzone/list", { params });
    slotzones = response.data;
  } catch (error) {
    console.log(error);
  }
  return slotzones;
};

function* fetchSlotZonesRequest(action) {
  try {
    const fetchedSlotZone = yield call(getSlotZones, action.payload);
    yield put(fetchSlotZonesSuccess(fetchedSlotZone));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getIssueTypes = async function (params) {
  let issueTypes = [];
  try {
    const response = await axios.get("/slotzone/issuetypes", { params });
    issueTypes = response.data;
  } catch (error) {
    console.log(error);
  }
  return issueTypes;
};

function* fetchIssueTypesRequest(action) {
  try {
    const fetchedIssueType = yield call(getIssueTypes, action.payload);
    yield put(fetchIssueTypesSuccess(fetchedIssueType));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getDays = async function (params) {
  let days = [];
  try {
    const response = await axios.get("/slotzone/daysInWeek", { params });
    days = response.data;
  } catch (error) {
    console.log(error);
  }
  return days;
};

function* fetchDaysRequest(action) {
  try {
    const fetchedDay = yield call(getDays, action.payload);
    yield put(fetchDaysSuccess(fetchedDay));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getSlots = async function (params) {
  let slots = [];
  try {
    const response = await axios.get("/slotzone/slots", { params });
    slots = response.data;
  } catch (error) {
    console.log(error);
  }
  return slots;
};

function* fetchSlotsRequest(action) {
  try {
    const fetchedSlot = yield call(getSlots, action.payload);
    yield put(fetchSlotsSuccess(fetchedSlot));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchSlotZoneRequest(action) {
  try {
    const slotzone = yield call(getSlotZone, {
      slotzoneId: action.payload.id,
    });
    yield put(fetchSlotZoneSuccess(slotzone));
  } catch (error) {
    yield put(fetchSlotZoneError(error.message));
  }
}

const getSlotZone = async function (params) {
  let slotzone = {};
  try {
    const response = await axios.get("/slotzone/" + params["slotzoneId"], {});
    slotzone = response.data;
  } catch (error) {
    console.log(error);
  }
  return slotzone;
};

const getSlotCountByZoneAndIssue = async function () {
  try {
    const response = await axios.get("/slotzone/slotsByZone/all");
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export function* fetchSlotZoneByZoneAndIssueRequest(action) {
  try {
    const fetchedSlotZones = yield call(getSlotCountByZoneAndIssue);
    yield put(fetchSlotCountByZoneAndIssueSuccess(fetchedSlotZones));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const getSlotCountByZones = async function (params) {
  try {
    const response = await axios.get("/slot/zoneWiseCategoryAvailableSlots", { params: { zoneCodes: params } });
    return response?.data?.body;
  } catch (error) {
    console.log(error);
  }
}

export function* fetchSlotCountByZones(action) {
  try {
    const fetchedSlots = yield call(getSlotCountByZones, action.payload);
    yield put(fetchSlotCategoryCountByZonesSuccess(fetchedSlots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const getAllCategories = async function () {
  try {
    const response = await axios.get("/slot/slotCategories");
    return response?.data?.body;
  } catch (error) {
    console.log(error);
  }
}

export function* fetchAllCategories() {
  try {
    const allCategories = yield call(getAllCategories);
    yield put(fetchAllCategoriesSuccess(allCategories));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export function* fetchSlotZonesData() {
  yield takeLatest(FETCH_ALL_SLOT_ZONE, fetchSlotZonesRequest);
  yield takeLatest(FETCH_SLOT_ZONE, fetchSlotZoneRequest);
  yield takeLatest(ADD_SLOT_ZONE, addSlotZoneRequest);
  yield takeLatest(UPDATE_SLOT_ZONE, updateSlotZoneRequest);
  yield takeLatest(FETCH_ISSUE_TYPES, fetchIssueTypesRequest);
  yield takeLatest(FETCH_DAYS, fetchDaysRequest);
  yield takeLatest(FETCH_SLOTS, fetchSlotsRequest);
  yield takeLatest(FETCH_SLOTS, fetchSlotsRequest);
  yield takeLatest(
    FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
    fetchSlotZoneByZoneAndIssueRequest
  );
  yield takeLatest(FETCH_SLOT_CATEGORY_COUNT_BY_ZONE, fetchSlotCountByZones);
  yield takeLatest(FETCH_ALL_SLOT_CATEGORIES, fetchAllCategories)
}

export default function* rootSaga() {
  yield all([fork(fetchSlotZonesData)]);
}
