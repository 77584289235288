import {
  FETCH_ALL_SLOT_CATEGORIES,
  FETCH_ALL_SLOT_CATEGORIES_SUCCESS,
  FETCH_ALL_SLOT_ZONE_SUCCESS,
  FETCH_DAYS_SUCCESS,
  FETCH_ISSUE_TYPES_SUCCESS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
  FETCH_SLOT_ZONE_SUCCESS,
  SHOW_API_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  slotCategoryByZone: undefined,
  categories: undefined
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SLOT_ZONE_SUCCESS: {
      return {
        ...state,
        allSlotZones: action.payload,
        slotZones: action.payload.records,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_SLOT_ZONE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_ISSUE_TYPES_SUCCESS: {
      return {
        ...state,
        issueTypes: action.payload,
        loader: false,
      };
    }
    case FETCH_DAYS_SUCCESS: {
      return {
        ...state,
        days: action.payload,
        loader: false,
      };
    }
    case FETCH_SLOTS_SUCCESS: {
      return {
        ...state,
        slots: action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS: {
      return {
        ...state,
        slotCountByZoneAndIssue: action.payload,
        loader: false,
      };
    }
    case FETCH_SLOT_CATEGORY_COUNT_BY_ZONE: {
      return {
        ...state,
        slotCategoryByZone: action.payload,
        loader: false
      }
    }
    case FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS: {
      return {
        ...state,
        slotCategoryByZone: action.payload,
        loader: false
      }
    }
    case FETCH_ALL_SLOT_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
        loader: false
      }
    }
    case FETCH_ALL_SLOT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        loader: false
      }
    }
    default:
      return state;
  }
};
