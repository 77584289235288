import {
  FETCH_ALL_AREA_SUCCESS,
  FETCH_ALL_CITIES_SUCCESS,
  FETCH_AREA_SUCCESS,
  FETCH_AREA_ZONES_BY_CITY_NAME,
  FETCH_AREA_ZONES_BY_CITY_NAME_SUCCESS,
  FETCH_CITIES_SUCCESS,
  FETCH_STATES_SUCCESS,
  FETCH_STATUSES_SUCCESS,
  FETCH_ZONES_SUCCESS,
  SHOW_API_MESSAGE
} from "constants/ActionTypes";

const INIT_STATE = {
  areas: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_AREA_SUCCESS: {
      return {
        ...state,
        allAreas: action.payload,
        areas: action.payload.areas,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_AREA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_STATES_SUCCESS: {
      return {
        ...state,
        states: action.payload,
        loader: false,
      };
    }
    case FETCH_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload,
        loader: false,
      };
    }
    case FETCH_ZONES_SUCCESS: {
      return {
        ...state,
        zones: action.payload,
        loader: false,
      };
    }
    case FETCH_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case FETCH_AREA_ZONES_BY_CITY_NAME: {
      return {
        ...state,
        zones: action.payload,
        loader: false
      }
    }
    case FETCH_AREA_ZONES_BY_CITY_NAME_SUCCESS: {
      return {
        ...state,
        zones: action.payload,
        loader: false
      }
    }
    default:
      return state;
  }
};
