import { showAPIMessage } from "actions/AreaActions";
import { fetchNextBillionJobsSuccess, fetchNextBillionOverviewSuccess, fetchNextBillionSolutionsSuccess, fetchNextBillionTasksSuccess, fetchNextBillionTechniciansSuccess, toggleNextBillionLoader } from "actions/NextBillion";
import { ADD_NEXT_BILLION_SOLUTION, FETCH_NEXT_BILLION_JOBS, FETCH_NEXT_BILLION_OVERVIEW, FETCH_NEXT_BILLION_SOLUTIONS, FETCH_NEXT_BILLION_TASKS, FETCH_NEXT_BILLION_TECHNICIANS } from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

const getNextBillionSolutions = async function (params) {
    let solutions = {};
    try {
        const res = await axios.get("/nextBillion/solutions", {
            params,
        });
        const response = res.data
        if (!response.success) {
            NotificationManager.error("Failed: " + response.message, '', 3000);
        }
        solutions = response.body
    } catch (error) {
        console.log(error);
    }
    return { ...solutions, isLoading: false };
};

function* fetchNextBillionSolutions(action) {
    try {
        const solutions = yield call(getNextBillionSolutions, action.payload);
        yield put(fetchNextBillionSolutionsSuccess(solutions));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

const getNextBillionTasks = async function (params) {
    let tasks = [];
    try {
        if (params) {
            const res = await axios.get("/nextBillion/tasks", {
                params
            });
            const response = res.data
            if (!response.success) {
                NotificationManager.error("Failed: " + response.message, '', 3000);
            }
            tasks = response.body
        }
    } catch (error) {
        console.log(error);
    }

    return tasks;
}

function* fetchNextBillionTasks(action) {
    try {
        const tasks = yield call(getNextBillionTasks, action.payload);
        yield put(fetchNextBillionTasksSuccess(tasks));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

const getNextBillionTechnicians = async function (params) {
    let technicians = [];
    try {
        const res = await axios.get("/employee/availableTechnicians", {
            params
        });
        const response = res.data
        if (!response.success) {
            NotificationManager.error("Failed: " + response.message, '', 3000);
        }
        technicians = response.body
    } catch (error) {
        console.log(error);
    }

    return technicians;
}

function* fetchNextBillionTechnicians(action) {
    try {
        const technicians = yield call(getNextBillionTechnicians, action.payload);
        yield put(fetchNextBillionTechniciansSuccess(technicians));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

const getNextBillionOverview = async function (params) {
    let overview = {};
    try {
        const res = await axios.get("/workforce/jobAndTechSummaryForRouting", {
            params
        });
        const response = res?.data
        if (!response?.success) {
            NotificationManager.error("Failed: " + response?.message, '', 3000);
        }
        overview = { details: response?.body }
    } catch (error) {
        console.log(error);
    }

    return overview;
}

function* fetchNextBillionOverview(action) {
    try {
        const overview = yield call(getNextBillionOverview, action.payload);
        yield put(fetchNextBillionOverviewSuccess(overview));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

const getRoutingJobsSummary = async function (id) {
    let jobs = {};
    try {
        const res = await axios.get(`/nextBillion/task/${id}`);
        const response = res.data;
        if (!response?.success) {
            NotificationManager.error("Failed: " + response.message, '', 3000);
        }
        jobs = response.body;
    } catch (error) {
        console.log(error);
    }
    return { ...jobs, isLoading: false };
}

function* fetchRoutingJobsSummary(action) {
    try {
        const overview = yield call(getRoutingJobsSummary, action.payload);
        yield put(fetchNextBillionSolutionsSuccess(overview));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

const addSolution = async function (params) {
    let jobs = {};

    try {
        const { routingId, callback } = params;
        if (routingId) {
            const res = await axios.post(`/nextBillion/apply?routingId=${routingId}`);
            const response = res.data;
            if (!response?.success) {
                NotificationManager.error("Failed: " + response.message, '', 3000);
            } else {
                NotificationManager.success(response.message, '', 3000);
                // TODO: Temporary solution
                setTimeout(() => {
                    if (callback && typeof callback === "function") {
                        callback();
                    }
                }, [3500])
            }
            jobs = response.body;
        }
    } catch (error) {
        console.log(error);
    }
    return { ...jobs, isLoading: false };
}

function* addRoutingSolution(action) {
    try {
        const overview = yield call(addSolution, action.payload);
        yield put(fetchNextBillionJobsSuccess(overview));
        yield put(toggleNextBillionLoader(false));
        yield put(fetchNextBillionTasksSuccess([]));
    } catch (error) {
        yield put(showAPIMessage(error));
    }
}

export function* fetchNextBillionSolutionsData() {
    yield takeLatest(FETCH_NEXT_BILLION_SOLUTIONS, fetchNextBillionSolutions)
    yield takeLatest(FETCH_NEXT_BILLION_TASKS, fetchNextBillionTasks)
    yield takeLatest(FETCH_NEXT_BILLION_TECHNICIANS, fetchNextBillionTechnicians)
    yield takeLatest(FETCH_NEXT_BILLION_OVERVIEW, fetchNextBillionOverview)
    yield takeLatest(FETCH_NEXT_BILLION_JOBS, fetchRoutingJobsSummary)
    yield takeLatest(ADD_NEXT_BILLION_SOLUTION, addRoutingSolution)
}

export default function* rootSaga() {
    yield all([fork(fetchNextBillionSolutionsData)])
}