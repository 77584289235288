import { ADD_NEXT_BILLION_SOLUTION, ADD_NEXT_BILLION_SOLUTIONS_SUCCESS, FETCH_NEXT_BILLION_JOBS, FETCH_NEXT_BILLION_JOBS_SUCCESS, FETCH_NEXT_BILLION_OVERVIEW, FETCH_NEXT_BILLION_OVERVIEW_SUCCESS, FETCH_NEXT_BILLION_SOLUTIONS, FETCH_NEXT_BILLION_SOLUTIONS_SUCCESS, FETCH_NEXT_BILLION_TASKS_SUCCESS, FETCH_NEXT_BILLION_TECHNICIANS, FETCH_NEXT_BILLION_TECHNICIANS_SUCCESS, TOGGLE_NEXT_BILLION_LOADER, TOGGLE_NEXT_BILLION_SOLUTION_LOADER } from "constants/ActionTypes";

const INIT_STATE = {
    solutions: { isLoading: false },
    tasks: [],
    technicians: [],
    overview: {},
    jobs: {},
    isSubmitLoading: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_NEXT_BILLION_SOLUTIONS: {
            return {
                ...state,
                solutions: { ...state.solutions, ...action.payload }
            }
        }
        case FETCH_NEXT_BILLION_SOLUTIONS_SUCCESS: {
            return {
                ...state,
                solutions: { ...state.solutions, ...action.payload }
            }
        }
        case FETCH_NEXT_BILLION_TASKS_SUCCESS: {
            return {
                ...state,
                tasks: action.payload
            }
        }
        case FETCH_NEXT_BILLION_TECHNICIANS: {
            return {
                ...state,
                technicians: action.payload
            }
        }
        case FETCH_NEXT_BILLION_TECHNICIANS_SUCCESS: {
            return {
                ...state,
                technicians: action.payload
            }
        }
        case FETCH_NEXT_BILLION_OVERVIEW: {
            return {
                ...state,
                overview: action.payload
            }
        }
        case FETCH_NEXT_BILLION_OVERVIEW_SUCCESS: {
            return {
                ...state,
                overview: action.payload
            }
        }
        case FETCH_NEXT_BILLION_JOBS: {
            return {
                ...state,
                jobs: action.payload
            }
        }
        case FETCH_NEXT_BILLION_JOBS_SUCCESS: {
            return {
                ...state,
                jobs: action.payload
            }
        }
        case ADD_NEXT_BILLION_SOLUTION: {
            return {
                ...state,
                jobs: action.payload
            }
        }
        case ADD_NEXT_BILLION_SOLUTIONS_SUCCESS: {
            return {
                ...state,
                jobs: action.payload
            }
        }
        case TOGGLE_NEXT_BILLION_LOADER: {
            return {
                ...state,
                isSubmitLoading: action.payload
            }
        }
        case TOGGLE_NEXT_BILLION_SOLUTION_LOADER: {
            return {
                ...state,
                solutions: { isLoading: action.payload }
            }
        }
        default:
            return state;
    }
}