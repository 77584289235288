import { ADD_NEXT_BILLION_SOLUTION, ADD_NEXT_BILLION_SOLUTIONS_SUCCESS, FETCH_NEXT_BILLION_JOBS, FETCH_NEXT_BILLION_JOBS_SUCCESS, FETCH_NEXT_BILLION_OVERVIEW, FETCH_NEXT_BILLION_OVERVIEW_SUCCESS, FETCH_NEXT_BILLION_SOLUTIONS, FETCH_NEXT_BILLION_SOLUTIONS_SUCCESS, FETCH_NEXT_BILLION_TASKS, FETCH_NEXT_BILLION_TASKS_SUCCESS, FETCH_NEXT_BILLION_TECHNICIANS, FETCH_NEXT_BILLION_TECHNICIANS_SUCCESS, TOGGLE_NEXT_BILLION_LOADER, TOGGLE_NEXT_BILLION_SOLUTION_LOADER } from "constants/ActionTypes";

export const fetchNextBillionSolutions = payload => {
    return {
        type: FETCH_NEXT_BILLION_SOLUTIONS,
        payload,
    };
};

export const fetchNextBillionSolutionsSuccess = payload => {
    return {
        type: FETCH_NEXT_BILLION_SOLUTIONS_SUCCESS,
        payload,
    };
};

export const fetchNextBillionTasks = payload => {
    return {
        type: FETCH_NEXT_BILLION_TASKS,
        payload,
    }
}

export const fetchNextBillionTasksSuccess = payload => {
    return {
        type: FETCH_NEXT_BILLION_TASKS_SUCCESS,
        payload
    }
}

export const fetchNextBillionTechnicians = payload => {
    return {
        type: FETCH_NEXT_BILLION_TECHNICIANS,
        payload,
    }
}

export const fetchNextBillionTechniciansSuccess = payload => {
    return {
        type: FETCH_NEXT_BILLION_TECHNICIANS_SUCCESS,
        payload
    }
}

export const fetchNextBillionOverview = payload => {
    return {
        type: FETCH_NEXT_BILLION_OVERVIEW,
        payload,
    }
}

export const fetchNextBillionOverviewSuccess = payload => {
    return {
        type: FETCH_NEXT_BILLION_OVERVIEW_SUCCESS,
        payload
    }
}

export const fetchNextBillionJobs = payload => {
    return {
        type: FETCH_NEXT_BILLION_JOBS,
        payload
    }
}

export const fetchNextBillionJobsSuccess = payload => {
    return {
        type: FETCH_NEXT_BILLION_JOBS_SUCCESS,
        payload
    }
}

export const addNextBillionSolution = payload => {
    return {
        type: ADD_NEXT_BILLION_SOLUTION,
        payload
    }
}

export const addNextBillionSolutionSuccess = payload => {
    return {
        type: ADD_NEXT_BILLION_SOLUTIONS_SUCCESS,
        payload
    }
}

export const toggleNextBillionLoader = payload => {
    return {
        type: TOGGLE_NEXT_BILLION_LOADER,
        payload
    }
}

export const toggleNextBillionSolutionLoader = payload => {
    return {
        type: TOGGLE_NEXT_BILLION_SOLUTION_LOADER,
        payload
    }
}