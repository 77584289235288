import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import AreaAPI from "./AreaReducer";
import assignAgent from "./assignAgentReducer";
import Auth from "./Auth";
import B2BpaymentReducer from "./B2BpaymentReducer";
import changePlanDuringDeliveryReducer from "./ChangePlanDuringDeliveryReducer";
import Common from "./Common";
import couponReducer from "./couponReducer";
import customerFormReducer from "./customerFormReducer";
import customerPlansReducer from "./customerPlansReducer";
import CustomerAPI from "./CustomerReducer";
import deliveryAgents from "./deliveryAgentsReducer";
import DeliveryDashboardAPI from "./DeliveryDashboardReducer";
import deliveryJobFormReducer from "./deliveryJobFormReducer";
import deliveryJob from "./deliveryJobReducer";
import DeliveryAPI from "./DeliveryReducer";
import DeviceAPI from "./DeviceReducer";
import EmployeeInventoryAPI from './EmployeeInventoryReducer';
import EmployeeAPI from "./EmployeeReducer";
import filters from "./filters";
import ItemsAPI from "./ItemsReducer";
import jobFormReducer from "./jobFormReducer";
import NewTechnicianAPI from './NewTechnicianReducer';
import NextBillion from "./NextBillion";
import notification from "./notificationReducer";
import pagination from "./pagination";
import PaymentAPI from "./PaymentReducer";
import Settings from "./Settings";
import SlotZoneAPI from "./SlotZoneReducer";
import sort from "./sort";
import SponsorAPI from "./SponsorReducer";
import TechnicianAPI from './TechnicianReducer';
import ui from "./uiReducer";
import updateJobAppointment from "./updateJobAppointmentReducer";
import updateJobStatus from "./updateJobStatusReducer";
import updateZip from "./updateZipReducer";
import { default as fetchLast3JobsByInstallation, default as JobAPI } from "./WorkforceReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    customerAPI: CustomerAPI,
    deliveryAPI: DeliveryAPI,
    DeliveryDashboardAPI: DeliveryDashboardAPI,
    employeeAPI: EmployeeAPI,
    technicianAPI: TechnicianAPI,
    newTechnicianAPI: NewTechnicianAPI,
    areaAPI: AreaAPI,
    slotZoneAPI: SlotZoneAPI,
    jobAPI: JobAPI,
    deviceAPI: DeviceAPI,
    filters,
    pagination,
    ui,
    sort,
    deliveryJob,
    updateJobStatus,
    updateZip,
    notification,
    updateJobAppointment,
    deliveryJobFormReducer,
    jobFormReducer,
    customerFormReducer,
    assignAgent,
    deliveryAgents,
    fetchLast3JobsByInstallation,
    form: reduxFormReducer,
    paymentAPI: PaymentAPI,
    sponsorAPI: SponsorAPI,
    EmployeeInventoryAPI,
    itemsAPI: ItemsAPI,
    customerPlans: customerPlansReducer,
    b2bpayment: B2BpaymentReducer,
    changePlanDuringDelivery: changePlanDuringDeliveryReducer,
    couponList: couponReducer,
    nextBillionAPI: NextBillion
  });
