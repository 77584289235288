import Button from "@material-ui/core/Button";
import { AssignmentLate, MotorcycleSharp } from "@material-ui/icons";
import PaymentIcon from "@material-ui/icons/Payment";
import { isAuthorised } from "actions/Auth";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else if (menuLi[j].classList.contains("open")) {
              menuLi[j].classList.remove("open");
            } else {
              menuLi[j].classList.add("open");
            }
          }
        }
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">DrinkPrime Modules</li>

          {/* <li className="menu collapse-box">
            <Button>
              <RecentActors fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Customer Module</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/customer/dashboard">
                  <span className="nav-text text-transform-none">
                    Dashboard
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/customer/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/customer/add">
                  <span className="nav-text">Add New</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void">
              <ContactPhone fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Support Module</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/coming-soon">
                  <span className="nav-text text-transform-none">
                    Dashboard
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/coming-soon">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          <li className="menu collapse-box">
            <Button>
              <AssignmentLate fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Workforce Module</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink className="prepend-icon" to="/app/workforce/dashboard">
                  <span className="nav-text text-transform-none">
                    Dashboard
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink className="prepend-icon" to="/app/workforce/calendar">
                  <span className="nav-text text-transform-none">Calendar</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/workforce/list">
                  <span className="nav-text">All Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/workforce/newAvailableSlots"
                >
                  <span className="nav-text">New Available Slots</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/workforce/availableSlots"
                >
                  <span className="nav-text">Available Slots</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/technician/list">
                  <span className="nav-text">Technicians List</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/workforce/GSDueDays">
                  <span className="nav-text">GS Due Days</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/workforce/RepeatJobs"
                >
                  <span className="nav-text">Repeat Jobs</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/workforce/item/list">
                  <span className="nav-text">Items List</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/workforce/routing/list">
                  <span className="nav-text">Routing</span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* <li className="menu collapse-box">
            <Button>
              <TrendingUp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Financial Module</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/coming-soon">
                  <span className="nav-text text-transform-none">
                    Dashboard
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/coming-soon">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          <li className="menu collapse-box">
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Delivery Module</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink className="prepend-icon" to="/app/delivery/dashboard">
                  <span className="nav-text text-transform-none">
                    Dashboard
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink className="prepend-icon" to="/app/delivery/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/delivery/calendar">
                  <span className="nav-text text-transform-none">Calendar</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/delivery/persons-list">
                  <span className="nav-text text-transform-none">
                    Delivery Persons List
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            className="menu collapse-box"
            style={
              isAuthorised(this.props.authUser, "SALES_3") ||
                isAuthorised(this.props.authUser, "PAYMENT_3") ||
                isAuthorised(this.props.authUser, "CRM_3")
                ? {}
                : { display: "none" }
            }
          >
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Employee</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/employee/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "SALES_4") ||
                    isAuthorised(this.props.authUser, "PAYMENT_4") ||
                    isAuthorised(this.props.authUser, "CRM_4")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink className="prepend-icon" to="/app/employee/add">
                  <span className="nav-text">Add New</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "DELIVERY_5") ||
                    isAuthorised(this.props.authUser, "OPERATIONS_5")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink className="prepend-icon" to="/app/employee/earning">
                  <span className="nav-text">Earnings Config</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li
            className="menu collapse-box"
            style={
              isAuthorised(this.props.authUser, "SALES_3") ||
                isAuthorised(this.props.authUser, "PAYMENT_3") ||
                isAuthorised(this.props.authUser, "CRM_3")
                ? {}
                : { display: "none" }
            }
          >
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Area</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/area/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "SALES_4") ||
                    isAuthorised(this.props.authUser, "PAYMENT_4") ||
                    isAuthorised(this.props.authUser, "CRM_4")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink className="prepend-icon" to="/app/area/add">
                  <span className="nav-text">Add New</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li
            className="menu collapse-box"
            style={
              isAuthorised(this.props.authUser, "SALES_3") ||
                isAuthorised(this.props.authUser, "PAYMENT_3") ||
                isAuthorised(this.props.authUser, "CRM_3")
                ? {}
                : { display: "none" }
            }
          >
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Slot</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/slotzone/all/list">
                  <span className="nav-text">New Default Slots</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/slotzone/current/list">
                  <span className="nav-text">Current Default Slots</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/slotzone/default/list"
                >
                  <span className="nav-text">List Default Slots</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "SALES_4") ||
                    isAuthorised(this.props.authUser, "PAYMENT_4") ||
                    isAuthorised(this.props.authUser, "CRM_4")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink
                  className="prepend-icon"
                  to="/app/slotzone/default/add"
                >
                  <span className="nav-text">Add Default Slot</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/slotzone/custom/list"
                >
                  <span className="nav-text">List Custom Slots</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "SALES_4") ||
                    isAuthorised(this.props.authUser, "PAYMENT_4") ||
                    isAuthorised(this.props.authUser, "CRM_3")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink className="prepend-icon" to="/app/slotzone/custom/add">
                  <span className="nav-text">Add Custom Slot</span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li className="menu collapse-box">
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Devices</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/device/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
          <li className="menu collapse-box">
            <Button>
              <PaymentIcon fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Customer</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/dashboard"
                >
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li> */}
              <li
              // style={
              //   isAuthorised(this.props.authUser, "PAYMENT_6")
              //     ? {}
              //     : { display: "none" }
              // }
              >
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/AllCustomers"
                >
                  <span className="nav-text">All Customers</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "OPERATIONS_6")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/B2BPayment"
                >
                  <span className="nav-text">B2B Payments</span>
                </NavLink>
              </li>

              {/* <li
                style={
                  isAuthorised(this.props.authUser, "PAYMENT_1")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/paymentLinkLookup"
                >
                  <span className="nav-text">Payment Link Lookup</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink className="prepend-icon" to="/app/installation/plan/list">
                  <span className="nav-text">Apply Initial Plans</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/paymentDashboard"
                >
                  <span className="nav-text">Payment Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/Reports"
                >
                  <span className="nav-text">Reports</span>
                </NavLink>
              </li>
              <li
                style={
                  isAuthorised(this.props.authUser, "CRM_5")
                    ? {}
                    : { display: "none" }
                }
              >
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/Rewards"
                >
                  <span className="nav-text">Rewards</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/customer/bulkMessage"
                >
                  <span className="nav-text">Bulk Message</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/installation/planDashboard"
                >
                  <span className="nav-text">Plans Dashboard</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/installation/plan/add">
                  <span className="nav-text">Add Plan</span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          {/* <li className="menu collapse-box">
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Technician</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/technician/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
          <li className="menu collapse-box">
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Sponsors</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/sponsor">
                  <span className="nav-text">All Sponsors</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/sponsor/bank-accounts"
                >
                  <span className="nav-text">Bank Accounts</span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/*<li className="menu collapse-box">
            <Button>
              <MotorcycleSharp fontSize="small" />
              &nbsp;&nbsp;
              <span className="nav-text">Technicians</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/technician/list">
                  <span className="nav-text">List All</span>
                </NavLink>
              </li>
            </ul>
          </li>
        */}
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  };
};

export default withRouter(connect(mapStateToProps)(SidenavContent));
